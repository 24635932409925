.gallery-page {
  min-height: 100vh;
  padding: 20px;
  background: #1a1a1a;
}

.gallery-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.gallery-container h1 {
  color: white;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.folder-grid {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
}

@media (min-width: 768px) {
  .folder-grid {
    padding: 2rem;
  }
}

.folder-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: white;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.folder-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.2);
}

.folder-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  color: #4a9eff;
}

.folder-icon svg {
  width: 100%;
  height: 100%;
}

.folder-name {
  font-size: 1.1rem;
  text-align: center;
  color: white;
} 