@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;1,400&display=swap');

.mainContainer {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.contentContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.contentOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 120px;
}

.sectionContent {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 2rem;
  color: white;
  font-family: 'Crimson Text', serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
}

.sectionContent h1 {
  font-size: 3rem;
  margin-bottom: 2rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Crimson Text', serif;
  font-weight: 600;
  letter-spacing: 1px;
}

.sectionContent p {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Crimson Text', serif;
  text-align: justify;
  text-indent: 2em;
  padding: 0;
  width: 100%;
}

.sectionContent p:first-of-type {
  text-indent: 0;
}

.sectionContent p:first-of-type::first-letter {
  font-size: 3em;
  float: left;
  padding-right: 0.1em;
  line-height: 0.85;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: 600;
}

.sectionContent h2 {
  font-size: 2rem;
  margin: 2rem 0 1rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.sectionContent ul {
  list-style-type: disc;
  margin-left: 2rem;
  margin-bottom: 1.5rem;
  color: white;
}

.sectionContent li {
  margin-bottom: 0.5rem;
  line-height: 1.6;
}

.warning {
  border-left: 4px solid #ff4444;
  color: #ff4444;
}

@media (max-width: 768px) {
  .contentOverlay {
    padding-bottom: 150px;
  }

  .sectionContent {
    margin: 0;
    width: 100%;
    padding: 1rem;
    padding-bottom: 100px !important;
  }

  .sectionContent p {
    font-size: 1rem;
    padding: 0;
  }

  .sectionContent h2 {
    font-size: 1.5rem;
  }
}

.chevron {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  font-size: 6rem;
  color: white;
  cursor: pointer;
  z-index: 100;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  user-select: none;
}

.chevron.left {
  left: 2rem;
}

.chevron.right {
  right: 2rem;
}

.dropdown {
  position: fixed;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  text-align: center;
}

.dropdown-title {
  font-size: 2.5rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  margin-bottom: 1rem;
}

.dropdown-menu {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  padding: 0.5rem;
  min-width: 200px;
}

.dropdown-item {
  color: white;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 4px;
}

.dropdown-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.welcome-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
  background: #0a0a0a;
}

.welcome-content {
  max-width: 800px;
  margin: 0 auto;
}

.content-container {
  background: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.content-container p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
}

.content-container p:last-child {
  margin-bottom: 0;
}

.veiled-paths {
  margin: 1rem 0;
  position: relative;
  display: inline-block;
  width: auto;
  text-align: center;
}

.veiled-paths h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #4a9eff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.veiled-links {
  display: none;
  position: absolute;
  background: rgba(10, 10, 10, 0.95);
  border: 1px solid rgba(74, 158, 255, 0.2);
  border-radius: 4px;
  min-width: 200px;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0.5rem;
}

.veiled-paths:hover .veiled-links {
  display: none;
}

.veiled-paths.active .veiled-links {
  display: flex;
  flex-direction: column;
}

.veiled-link {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.veiled-link:hover {
  background: rgba(74, 158, 255, 0.2);
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.9);
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.5);
}
  