/* Base layout */
.species-page {
  min-height: 100vh;
  background: #0a0a0a;
  color: white;
  padding-bottom: 400px;
}

/* Hero section */
.species-hero {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.species-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.species-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: rgba(10, 10, 10, 0.7);
  backdrop-filter: blur(5px);
  padding: 2rem;
  z-index: 2;
}

.species-title h1 {
  font-size: 4rem;
  margin: 0;
  padding: 0;
  color: white;
  text-shadow: 0 0 20px rgba(74, 158, 255, 0.5);
}

.species-brief {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
  max-width: 800px;
  margin: 1rem auto;
  font-style: italic;
}

.species-details {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.content-block {
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: rgba(74, 158, 255, 0.05);
  border: 1px solid rgba(74, 158, 255, 0.1);
  border-radius: 8px;
}

.content-block h2 {
  color: #4a9eff;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.content-block p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 1rem;
}

.trait {
  padding: 1rem;
  background: rgba(74, 158, 255, 0.05);
  border: 1px solid rgba(74, 158, 255, 0.1);
  border-radius: 8px;
  margin-bottom: 1rem;
}

.trait-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.trait-header h3 {
  color: #4a9eff;
  font-size: 1.4rem;
  margin: 0;
}

.level {
  color: #4a9eff;
  font-size: 1rem;
  padding: 0.2rem 0.5rem;
  border: 1px solid rgba(74, 158, 255, 0.3);
  border-radius: 4px;
}

.specializations-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.specializations-list li {
  margin-bottom: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;
}

.specializations-list li strong {
  color: #4a9eff;
}

/* Image Modal Styles */
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-image-container {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
  margin: 2rem;
}

.modal-image {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

.modal-close {
  position: absolute;
  top: -40px;
  right: -40px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
}

.modal-download {
  position: absolute;
  bottom: -40px;
  right: -40px;
  color: white;
  text-decoration: none;
  font-size: 24px;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .species-hero {
    height: 400px;
  }

  .species-title h1 {
    font-size: 2.5rem;
  }
  
  .species-details {
    padding: 1rem;
  }
  
  .content-block h2 {
    font-size: 1.5rem;
  }
  
  .trait-header h3 {
    font-size: 1.2rem;
  }

  .modal-close {
    top: 10px;
    right: 10px;
  }

  .modal-download {
    bottom: 10px;
    right: 10px;
  }
}