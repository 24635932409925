@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1a1a1a;
  color: #ffffff;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Utility classes */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* Deity modal styles - scoped to avoid conflicts */
.deity-page .deity-modal-content {
  text-align: left;
}

.deity-page .deity-modal-content p,
.deity-page .deity-modal-content h3,
.deity-page .deity-modal-content div {
  text-align: left;
}
