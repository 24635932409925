@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;1,400&display=swap');

.species-welcome {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 80px 0;
  background: #0a0a0a;
  overflow-y: auto;
  position: relative;
}

.species-content {
  text-align: center;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 1000;
}

.species-content h1 {
  font-size: 3rem;
  color: white;
  font-weight: 600;
  text-shadow: 0 0 20px rgba(74, 158, 255, 0.5);
  letter-spacing: 2px;
  margin-bottom: 2rem;
  font-family: 'Crimson Text', serif;
}

.content-section {
  text-align: left;
}

.content-section p {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Crimson Text', serif;
  text-align: justify;
  text-indent: 2em;
}

.content-section p:first-of-type {
  text-indent: 0;
}

.content-section p:first-of-type::first-letter {
  font-size: 3em;
  float: left;
  padding-right: 0.1em;
  line-height: 0.85;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: 600;
}

.species-paths {
  margin: 1rem 0;
  position: relative;
  display: inline-block;
}

.species-paths h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #4a9eff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.species-links {
  display: none;
  position: absolute;
  background: rgba(10, 10, 10, 0.95);
  border: 1px solid rgba(74, 158, 255, 0.2);
  border-radius: 4px;
  min-width: 200px;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
}

.species-paths.active .species-links {
  display: flex;
  flex-direction: column;
}

.species-link {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.species-link:hover {
  background: rgba(74, 158, 255, 0.2);
}

@media (max-width: 768px) {
  .species-links {
    grid-template-columns: 1fr;
  }
} 