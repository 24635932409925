/* Reset and Base Styles */
:root {
  /* Colors */
  --color-background: #1a1a1a;
  --color-text: #ffffff;
  --color-primary: #4a9eff;
  --color-overlay: rgba(0, 0, 0, 0.95);
  --color-overlay-light: rgba(0, 0, 0, 0.8);
  --color-border: rgba(255, 255, 255, 0.1);

  /* Spacing */
  --spacing-xs: 0.5rem;
  --spacing-sm: 1rem;
  --spacing-md: 1.5rem;
  --spacing-lg: 2rem;
  --spacing-xl: 3rem;

  /* Z-index layers */
  --z-modal: 9000;
  --z-modal-header: 9001;
  --z-progress: 9002;
  --z-menu: 9999;
}

/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

/* Base styles */
body {
  background: var(--color-background);
  color: var(--color-text);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  min-height: 100vh;
  overflow-x: hidden;
  -webkit-touch-callout: none;
}

/* Allow text selection only in specific elements where needed */
article p, 
article h1, 
article h2, 
article h3, 
article h4, 
article h5, 
article h6,
.content-section p,
.content-section h1,
.content-section h2,
.content-section h3,
.content-section h4,
.content-section h5,
.content-section h6 {
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  -webkit-touch-callout: default;
}

/* Prevent selection on ALL interactive and navigation elements */
button,
a,
nav,
header,
.menu-label,
.main-menu,
.main-menu *,
[role="button"],
[role="navigation"],
.nav-link,
.nav-links *,
.folder-card,
.folder-name {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

/* Layout */
.main-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: var(--spacing-sm);
}

.content-grid {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  padding: var(--spacing-sm);
  margin: 0 auto;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.4);
}

/* Shared Welcome Page Styles */
.welcome-page {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  padding-top: 80px;
  background: transparent;
}

.welcome-content {
  text-align: center;
  padding: 2rem;
  background: transparent;
  position: relative;
  z-index: 1000;
}

.welcome-content h1 {
  font-size: 4.5rem;
  color: white;
  font-weight: 600;
  text-shadow: 0 0 20px rgba(74, 158, 255, 0.5);
  letter-spacing: 2px;
  margin: 0;
  line-height: 1.2;
  background: linear-gradient(45deg, #ffffff, #4a9eff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  animation: gradient 8s ease infinite;
  position: relative;
  z-index: 101;
}

.welcome-icon {
  color: #4a9eff;
  margin-bottom: 1rem;
}

.welcome-subtitle {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 1rem;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} 