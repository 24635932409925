.gallery-folder-page {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(to bottom, #1a1a1a, #0a0a0a);
  color: white;
}

.gallery-folder-container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.gallery-header {
  padding: 2rem;
  background: rgba(0, 0, 0, 0.5);
}

.nav-links {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.nav-link {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  transition: background 0.3s ease;
}

.nav-link:hover {
  background: rgba(255, 255, 255, 0.2);
}

.gallery-header h1 {
  font-size: 1.8rem;
  font-weight: 500;
  margin: 0;
  text-transform: capitalize;
  background: linear-gradient(90deg, #fff, #a5a5a5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.1);
}

.gallery-content {
  padding: 2rem;
}

.folder-grid {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
}

.folder-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  text-decoration: none;
  color: white;
  transition: transform 0.3s ease, background 0.3s ease;
}

.folder-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.2);
}

.folder-icon {
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
}

.folder-name {
  text-align: center;
  font-weight: 500;
}

.image-grid {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.image-card {
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  aspect-ratio: 16/9;
  cursor: pointer;
}

.image-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-card:hover img {
  transform: scale(1.05);
}

.image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  transform: translateY(100%);
  transition: transform 0.3s ease;
}

.image-card:hover .image-overlay {
  transform: translateY(0);
}

.image-name {
  color: white;
  font-weight: 500;
}

/* Modal Styles */
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 2rem;
}

.image-modal img {
  max-width: 90vw;
  max-height: 90vh;
  object-fit: contain;
}

.close-button,
.download-button {
  position: absolute;
  top: 1rem;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
}

.close-button {
  right: 1rem;
  font-size: 24px;
}

.download-button {
  right: 4rem;
}

.close-button:hover,
.download-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.empty-state {
  text-align: center;
  padding: 4rem;
  color: rgba(255, 255, 255, 0.6);
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 768px) {
  .gallery-folder-container {
    padding: 10px;
    width: 100%;
  }

  .gallery-header {
    padding: 10px;
    margin: -10px -10px 15px -10px;
    flex-direction: column;
    gap: 10px;
  }

  .nav-links {
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .nav-link {
    padding: 6px 10px;
    font-size: 0.8rem;
    white-space: nowrap;
  }

  .gallery-header h1 {
    font-size: 1.2rem;
    text-align: center;
    width: 100%;
  }

  .folder-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;
    padding: 0 5px;
  }

  .image-grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 10px;
    padding: 0 5px;
  }

  .folder-card {
    padding: 15px;
  }

  .folder-icon {
    width: 35px;
    height: 35px;
  }

  .folder-name {
    font-size: 0.85rem;
  }

  .image-modal img {
    max-width: 95vw;
    max-height: 80vh;
  }

  .close-button,
  .download-button {
    width: 32px;
    height: 32px;
  }

  .close-button {
    top: 5px;
    right: 5px;
    font-size: 20px;
  }

  .download-button {
    bottom: 5px;
    right: 5px;
  }

  .download-button svg {
    width: 20px;
    height: 20px;
  }
}

/* Prevent scrolling when modal is open */
body.modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: #fff;
  font-size: 1.2rem;
}

.loading-spinner::after {
  content: '';
  width: 30px;
  height: 30px;
  margin-left: 15px;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .folder-grid,
  .image-grid {
    padding: 2rem;
  }
} 