/* Base layout */
.speciespage {
  min-height: 100vh;
  background: #0a0a0a;
  color: white;
  padding: 0 1rem 2rem 1rem;
}

/* Hero section */
.speciespage-hero {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
}

.speciespage-background {
  width: 100%;
  height: auto;
  max-height: 600px;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.speciespage-title {
  text-align: center;
  margin-top: -2rem;
}

.speciespage-title h1 {
  display: inline-block;
  font-size: 4rem;
  margin: 0;
  padding: 0.5rem 1rem;
  background: #0a0a0a;
  border-radius: 4px;
  line-height: 1.2;
}

/* Content section */
.speciespage-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

/* Content blocks */
.content-block {
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: rgba(74, 158, 255, 0.05);
  border: 1px solid rgba(74, 158, 255, 0.1);
  border-radius: 8px;
}

.content-block h2 {
  color: #4a9eff;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.content-block p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.9);
}

/* Traits */
.trait {
  padding: 1rem;
  background: rgba(74, 158, 255, 0.05);
  border: 1px solid rgba(74, 158, 255, 0.1);
  border-radius: 8px;
  margin-bottom: 1rem;
}

.trait-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trait-header h3 {
  color: #4a9eff;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.level-tag {
  background-color: rgba(74, 158, 255, 0.2);
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #4a9eff;
  font-weight: normal;
}

.trait p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
}

/* Lists */
.specializations-list {
  list-style: none;
  padding-left: 0;
  margin-top: 1rem;
}

.specializations-list li {
  margin: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
  color: rgba(255, 255, 255, 0.8);
}

.specializations-list li::before {
  content: "•";
  color: #4a9eff;
  position: absolute;
  left: 0;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .speciespage-title {
    margin-top: -1rem;
  }
  
  .speciespage-title h1 {
    font-size: 2.5rem;
  }
  
  .content-block h2 {
    font-size: 1.5rem;
  }
  
  .trait-header h3 {
    font-size: 1.2rem;
  }

  .speciespage-background {
    width: 100%;
    max-width: 400px;
    max-height: 400px;
  }
}

/* Modal styles */
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-image-container {
  position: relative;
  height: 85vh;
  width: 100%;
  max-width: 1200px;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  height: 100%;
  width: auto;
  max-height: calc(100vh - 4rem);
  max-width: 100%;
  object-fit: contain;
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 10000;
  padding: 10px;
  text-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.modal-download {
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  text-decoration: none;
  font-size: 24px;
  z-index: 10000;
  padding: 10px;
  text-shadow: 0 0 10px rgba(0,0,0,0.5);
}

/* Path navigation */
.speciespage-navigation {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 2rem auto;
}

.speciespage-navigation h2 {
  color: #4a9eff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.speciespage-list {
  display: none;
  position: absolute;
  background: rgba(10, 10, 10, 0.95);
  border: 1px solid rgba(74, 158, 255, 0.2);
  border-radius: 4px;
  min-width: 200px;
  z-index: 1000;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  list-style: none;
  padding: 0;
}

.speciespage-navigation:hover .speciespage-list {
  display: flex;
  flex-direction: column;
}

.speciespage-link {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.speciespage-link:hover {
  background: rgba(74, 158, 255, 0.2);
}

/* Add this to center the menu */
.menu-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 2rem 0;
  position: relative;
}

.speciespage-menu {
  position: relative;
  text-align: center;
  display: inline-block;
}

.speciespage-menu h2 {
  color: #4a9eff;
  font-size: 1.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
}

.speciespage-menu .speciespage-links {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(10, 10, 10, 0.95);
  border: 1px solid rgba(74, 158, 255, 0.2);
  border-radius: 4px;
  padding: 0.5rem 0;
  z-index: 1000;
  min-width: 200px;
}

.speciespage-menu.active .speciespage-links {
  display: block;
}

.speciespage-link {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  white-space: nowrap;
  text-align: left;
}

.speciespage-link:hover {
  background: rgba(74, 158, 255, 0.2);
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .speciespage-menu h2 {
    font-size: 1.5rem;
  }
  
  .speciespage-links {
    width: 90vw;
    max-width: none;
  }
  
  .speciespage-link {
    padding: 1rem;
    font-size: 1.1rem;
  }
} 